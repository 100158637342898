import React, { useEffect } from 'react';
import { motion } from 'framer-motion';

const Landing = () => {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
        }
      });
    });

    document.querySelectorAll('.fade-in').forEach((el) => observer.observe(el));
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-slate-900 via-purple-900 to-indigo-950 text-emerald-50">
      <nav className="fixed w-full backdrop-blur-md bg-slate-900/30 z-50 border-b border-emerald-800/20">
        <div className="max-w-7xl mx-auto px-6 py-4 flex justify-between items-center">
          <motion.h1 
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            className="text-3xl font-bold bg-gradient-to-r from-emerald-400 to-teal-400 bg-clip-text text-transparent"
          >
            NAPTA
          </motion.h1>
          <motion.div 
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            className="space-x-8 hidden md:flex items-center"
          >
            <a href="#features" className="text-emerald-300 hover:text-teal-400 transition-colors">Features</a>
            <a href="#solutions" className="text-emerald-300 hover:text-teal-400 transition-colors">Solutions</a>
            <a href="#contact" className="text-emerald-300 hover:text-teal-400 transition-colors">Contact</a>
            <button className="bg-gradient-to-r from-emerald-500 to-teal-500 px-6 py-2 rounded-full hover:shadow-lg hover:shadow-emerald-500/20 transition-all hover:scale-105 text-emerald-50">
              Get Started
            </button>
          </motion.div>
          <button className="md:hidden text-emerald-300">
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16m-7 6h7" />
            </svg>
          </button>
        </div>
      </nav>

      <main>
        <section className="min-h-screen flex items-center justify-center relative overflow-hidden">
          <div className="absolute inset-0 bg-[url('/public/grid.svg')] opacity-10 animate-pulse"></div>
          <div className="absolute inset-0 bg-gradient-radial from-emerald-500/10 via-transparent to-transparent"></div>
          <div className="container mx-auto px-6 py-20 relative">
            <motion.div 
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-center max-w-4xl mx-auto"
            >
              <h2 className="text-5xl md:text-7xl font-bold mb-6 bg-gradient-to-r from-emerald-400 to-teal-400 bg-clip-text text-transparent animate-gradient">
                The Future of AI Communication
              </h2>
              <p className="text-xl text-emerald-300 mb-12 leading-relaxed">
                Revolutionize your business interactions with cutting-edge AI solutions for calls and chat
              </p>
              <div className="space-x-4 flex flex-col md:flex-row justify-center gap-4">
                <button className="bg-gradient-to-r from-emerald-500 to-teal-500 px-8 py-4 rounded-full text-lg font-semibold hover:shadow-lg hover:shadow-emerald-500/20 transition-all hover:scale-105 flex items-center justify-center gap-2 text-emerald-50">
                  <span>Start Free Trial</span>
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 8l4 4m0 0l-4 4m4-4H3" />
                  </svg>
                </button>
                <button className="border border-emerald-400 px-8 py-4 rounded-full text-lg font-semibold text-emerald-300 hover:bg-emerald-500/20 transition-all flex items-center justify-center gap-2">
                  <span>Watch Demo</span>
                  <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </button>
              </div>
            </motion.div>
          </div>
        </section>

        <section id="features" className="py-20 bg-slate-900/50 relative">
          <div className="absolute inset-0 bg-gradient-to-b from-transparent to-emerald-950/20"></div>
          <div className="container mx-auto px-6 relative">
            <h2 className="text-4xl font-bold text-center mb-16 bg-gradient-to-r from-emerald-400 to-teal-400 bg-clip-text text-transparent">
              Why Choose NAPTA?
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {features.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ delay: index * 0.2 }}
                  className="group p-8 rounded-2xl border border-emerald-800/50 bg-gradient-to-b from-slate-900/50 to-emerald-950/30 backdrop-blur-sm hover:border-emerald-600/50 transition-all duration-300 hover:scale-105"
                >
                  <div className="text-4xl mb-4 group-hover:scale-110 transition-transform duration-300">{feature.icon}</div>
                  <h3 className="text-2xl font-bold mb-4 bg-gradient-to-r from-emerald-400 to-teal-400 bg-clip-text text-transparent">{feature.title}</h3>
                  <p className="text-emerald-300 leading-relaxed">{feature.description}</p>
                </motion.div>
              ))}
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

const features = [
  {
    title: "AI-Powered Calls",
    description: "Smart call handling with real-time AI assistance and analytics",
    icon: "🎯"
  },
  {
    title: "Intelligent Chat",
    description: "Context-aware chatbots delivering human-like interactions",
    icon: "💬"
  },
  {
    title: "Analytics Dashboard",
    description: "Comprehensive insights into your communication metrics",
    icon: "📊"
  }
];

export default Landing; 